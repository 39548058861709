import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {CategoryLanding} from '../components/CategoryLanding'


const NotFoundPage = ({data}) => (
  <Layout generic headerTitle='UH-OH! SOMETHING WENT WRONG.' headerText='Looks like you took the wrong turn at the intersection. Fret not, there&#39;s a lot to discover, so keep exploring!'>
    <SEO title="404: Not found" />

    {/* <CategoryLanding error={data.allWordpressPage.edges}  introDesc='Looks like you took the wrong turn at the intersection. Fret not, there&#39;s a lot to discover, so keep exploring!' /> */}

  </Layout>
)


export default NotFoundPage

// export const pageQuery = graphql`

// {

//   allWordpressPage(filter:{wordpress_id: {in:[1468, 73, 289]}}) {
//     edges {
//       node {
//         title
//         path
//         acf {
//           intro_description
//           ...featureImagePage
//         }
//       }
//     }
//   }
// }


// `

