import styled from 'styled-components';


export const GridWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
	grid-auto-flow: row;
	justify-content: center;
	padding-bottom: 50px;
	grid-auto-flow: dense;


	@media (min-width: 480px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 30PX;
	}



	@media (min-width: 1280px) {
		grid-gap: 55px;
		padding-bottom: 100px;

	}

`;


export const ThreeGrid = styled.div`
	/* padding-top: 30px; */
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 18px;

	@media (min-width: 480px) {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 10px;
	}

	@media (min-width: 768px) {
		/* padding-top: 50px; */
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media (min-width: 1024px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 55px;
	}
`;