import React, { Component } from 'react'

import { Teaser } from '../Teaser'
import {
  ComponentContainer,
  OverflowContainer,
  SubText,
} from '../../utils/globalStyledComponents'
import { GridWrapper, ThreeGrid } from './style.js'

class CategoryLanding extends Component {
  render() {
    console.log('this.props.subcategories:', this.props.subcategories)

    return (
      <ComponentContainer>
        <OverflowContainer>
          {(this.props.introHeading || this.props.introDesc) && (
            <SubText>
              {this.props.introHeading && <h4>{this.props.introHeading}</h4>}

              {this.props.introDesc && <p>{this.props.introDesc}</p>}
            </SubText>
          )}

          {this.props.error ? (
            <ThreeGrid>
              {this.props.error.map((teaser, index) => {
                return (
                  <Teaser
                    key={index}
                    standard
                    error
                    url={teaser.uri}
                    title={teaser.title}
                    img={{
                      ...teaser.avContent.featureImage,
                      sizes: '364px',
                    }}
                    desc={teaser.avIntro.introDescription}
                  />
                )
              })}
            </ThreeGrid>
          ) : (
            <GridWrapper>
              {this.props.subcategories ? (
                this.props.subcategories.map((teaser, index) => (
                  <Teaser
                    key={index}
                    gridLarge={this.props.gridVar ? null : index === 0}
                    gridLong={this.props.gridVar ? index === 0 : index === 2}
                    categoryLanding
                    img={{
                      ...teaser.avContent.featureImage,
                      sizes: index === 0 || index === 2 ? '1200px' : '600px',
                    }}
                    descHeading={teaser.avIntro.introHeading}
                    title={teaser.title }
                    url={teaser.uri}
                  />
                ))
              ) : (
                <>
                  <Teaser
                    gridLarge
                    categoryLanding
                    foodCategory="Gastro Pubs"
                    image="https://res.cloudinary.com/avenue/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1541979543/ferns_by_izaac_x2pa1m.jpg"
                    title="jordie lane at the cabaret club"
                  />
                  <Teaser
                    categoryLanding
                    foodCategory="Brewery"
                    image="https://res.cloudinary.com/avenue/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1541979543/ferns_by_izaac_x2pa1m.jpg"
                    title="jordie lane at the cabaret club"
                  />
                  <Teaser
                    gridLong
                    categoryLanding
                    foodCategory="Brewery"
                    image="https://res.cloudinary.com/avenue/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1541979543/ferns_by_izaac_x2pa1m.jpg"
                    title="jordie lane at the cabaret club"
                  />
                  <Teaser
                    categoryLanding
                    foodCategory="Carnivore"
                    image="https://res.cloudinary.com/avenue/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1541979543/ferns_by_izaac_x2pa1m.jpg"
                    title="jordie lane at the cabaret club"
                  />
                </>
              )}
            </GridWrapper>
          )}
        </OverflowContainer>
      </ComponentContainer>
    )
  }
}

export default CategoryLanding
